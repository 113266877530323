import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import { debounce } from '@utils/debounce'
import { wpautop } from '@utils/wpautop'
import parse from 'html-react-parser'

// Components
import Breadcrumbs from '@components/seo/Breadcrumbs'
import ImagineStudioDeviceBanner from '@components/global/devices/ImagineStudioDeviceBanner'

// Component last order
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'

// Element
import ElementImage from '@components/global/element/Image'

// CSS
import * as cx from './ImagingStudioBanner.module.scss'

const ISBanner = ({
  wrapperClassName = '',
  breadcrumbs,
  title = '',
  subtitle = '',
  description = '',
  button_text = '',
  button_link = '/',
  background = '',
  devices,
}) => {
  const [isMobile, setIsMobile] = useState(false)
  const modalFunc = useRef()
  const {
    wp: {
      homeTopFold: { logo },
    },
  } = useStaticQuery(graphql`
    {
      wp {
        homeTopFold {
          logo {
            image {
              url
              width
              height
              alt
              srcset
              sizes
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    let isMounted = true

    const detectSize = debounce(() => {
      if (isMounted) {
        setIsMobile(window.innerWidth <= 991)
      }
    }, 50)

    detectSize()

    window.addEventListener('resize', detectSize)

    return () => {
      isMounted = false
      window.removeEventListener('resize', detectSize)
      // setIsMobile(false);
    }
  })

  return (
    <>
      <section
        className={`${cx.wrapper} ${wrapperClassName}`}
        style={{ backgroundImage: `url(${background})` }}
      >
        <Breadcrumbs data={breadcrumbs} wrapperClassName={cx.breadcrumb} />
        <ModalForm
          childFunc={modalFunc}
          title={'Ready To Build An <br>Award-Winning Website?'}
          template={'default'}
        >
          <FormSignUp
            submitLabel="Boost your online presence"
            labelClassName={'desktop-only'}
            leadName="IS Banner Modal"
          />
        </ModalForm>

        <div className={cx.heading}>
          <h1 className="heading-1">{parse(title)}</h1>

          {parse(wpautop(description, 'default-body large'))}
        </div>

        <div className={cx.button}>
          <button
            className="primary-button large"
            onClick={(e) => modalFunc.current.openModal()}
          >
            {button_text}
          </button>
        </div>

        <div className={cx.bannerDevice}>
          <ImagineStudioDeviceBanner
            mobileSrc={devices.mobile}
            tabletSrc={devices.tablet}
            laptopSrc={devices.laptop}
          />
        </div>
      </section>

      <div className={cx.vipLogos}>
        <Splide
          hasTrack={false}
          tag="div"
          extensions={{ AutoScroll }}
          options={{
            type: 'loop',
            rewind: true,
            arrows: false,
            pagination: false,
            autoHeight: false,
            drag: false,
            perPage: 10,
            autoScroll: {
              speed: 2,
              pauseOnHover: false,
              pauseOnFocus: false,
              rewind: true,
            },
          }}
          className={cx.carousel}
        >
          <SplideTrack>
            {logo.map((item, index) => {
              return (
                <SplideSlide key={index}>
                  <ElementImage
                    src={item.image.url}
                    width={item.image.width}
                    height={item.image.height}
                    alt={item.image.alt}
                  />
                </SplideSlide>
              )
            })}
            {/* Clone */}
            {logo.map((item, index) => {
              return (
                <SplideSlide key={index}>
                  <ElementImage
                    src={item.image.url}
                    width={item.image.width}
                    height={item.image.height}
                    alt={item.image.alt}
                  />
                </SplideSlide>
              )
            })}
          </SplideTrack>
        </Splide>
      </div>
    </>
  )
}

ISBanner.propTypes = {
  wrapperClassName: PropTypes.string,
  breadcrumbs: PropTypes.array.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  button_text: PropTypes.string,
  button_link: PropTypes.string,
  devices: PropTypes.object,
  background: PropTypes.string,
}

export default ISBanner
