import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide'

import ElementImage from '@components/global/element/Image'
import VideoThumbnail from '@components/global/modal/VideoThumbnail'
import SpliderButtons from '@components/global/buttons/SpliderButtons'
import * as cx from './Spotlight.module.scss'

const RewImagineStudioSpotlight = ({
  title = 'Agent spotlight',
  background = '/content/assets/real-estate-websites/imagine-studio/spotlight-bg.jpg',
  items,
}) => {
  return (
    <section className={cx.wrapper}>
      <div
        className={cx.wrapperBg}
        style={{ backgroundImage: `url(${background})` }}
      ></div>
      <div className={cx.sliderContent}>
        <p className={`subtitle-1 ${cx.title}`}>{title}</p>
        <Splide
          hasTrack={false}
          tag="div"
          options={{
            type: 'loop',
            arrows: 2,
            arrowPath: false,
            pagination: false,
            perPage: 1,
            autoHeight: false,
            lazyLoad: 'sequential',
            autoplay: false,
            interval: 5000,
          }}
          className={`${cx.carousel}`}
        >
          <SplideTrack>
            {items &&
              items.map((item, index) => {
                return (
                  <SplideSlide key={index}>
                    <ul className={cx.item}>
                      <li className={cx.itemContent}>
                        <p className="heading-5">{item?.title}</p>
                        <p className="subtitle-5">{item?.description}</p>
                        <Link to="/" className="primary-button large">
                          Learn More
                        </Link>
                      </li>
                      <li className={cx.itemImage}>
                        <ElementImage
                          src={item?.image?.url}
                          width={item?.image?.width}
                          height={item?.image?.height}
                          alt={item?.title}
                          splideLazyload={true}
                        />
                        <div className={cx.floatingLogo}>
                          <ElementImage
                            src={item?.logo?.url}
                            width={item?.logo?.width}
                            height={item?.logo?.height}
                            alt={item?.title}
                          />
                        </div>
                      </li>
                    </ul>
                  </SplideSlide>
                )
              })}
          </SplideTrack>
          <SpliderButtons withText />
        </Splide>
      </div>
    </section>
  )
}

RewImagineStudioSpotlight.propTypes = {
  title: PropTypes.string,
  background: PropTypes.string,
  items: PropTypes.any,
}

export default RewImagineStudioSpotlight
