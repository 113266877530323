import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { modifyImageKeys } from '@utils/modifyImageKeys'

import ModalVideo from '@components/global/modal/Video'
import ElementImage from '@components/global/element/Image'
import ElementImageFile from '@components/global/element/ImageFile'
import SvgPlay from '@svg/play.inline'
import * as cx from './VideoThumbnail.module.scss'

const VideoThumbnail = ({
  wrapperClass,
  video,
  thumbnail,
  image_data = false,
  title,
  button_text,
  lazy = false,
}) => {
  const videoModal = useRef()

  return (
    <div
      className={`${cx.wrapper} ${wrapperClass}`}
      role="button"
      onClick={(e) => videoModal.current.openModal()}
    >
      <ModalVideo childFunc={videoModal} url={video} />

      {image_data && (
        <ElementImageFile
          src={modifyImageKeys(thumbnail)}
          alt={title}
          lazy={lazy}
        />
      )}

      {!image_data && (
        <ElementImage
          src={thumbnail.url}
          width={thumbnail.width}
          height={thumbnail.height}
          alt={title}
          lazy={lazy}
        />
      )}
      <div>
        <span>
          <SvgPlay />
        </span>{' '}
        <em>{button_text}</em>
      </div>
    </div>
  )
}

VideoThumbnail.propTypes = {
  wrapperClass: PropTypes.string,
  video: PropTypes.string,
  thumbnail: PropTypes.any,
  image_data: PropTypes.bool,
  title: PropTypes.string,
  button_text: PropTypes.string,
  lazy: PropTypes.bool,
}

export default VideoThumbnail
