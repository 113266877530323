import React from 'react'
import PropTypes from 'prop-types'

import ImageBg from '/content/assets/real-estate-websites/imagine-studio/divider-bg.jpg'
import * as cx from './Divider.module.scss'
import parse from 'html-react-parser'

const RewImagineStudioDivider = ({
  title = 'Luxury Websites That Elevate Your Brand',
  description = 'These luxury themes are a stunning combination of contemporary design <br />and upscale styling.',
}) => {
  return (
    <section
      className={cx.wrapper}
      style={{ backgroundImage: `url(${ImageBg})` }}
    >
      <h2 className="subtitle-8">{title}</h2>
      <p className="default-body large">{parse(description)}</p>
    </section>
  )
}

RewImagineStudioDivider.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default RewImagineStudioDivider
