// extracted by mini-css-extract-plugin
export var carousel = "Spotlight-module--carousel--237e2";
export var floatingLogo = "Spotlight-module--floating-logo--910d8";
export var floatingReview = "Spotlight-module--floating-review--775c0";
export var item = "Spotlight-module--item--180a2";
export var itemContent = "Spotlight-module--item-content--c9565";
export var itemImage = "Spotlight-module--itemImage--3fc4e";
export var sliderContent = "Spotlight-module--slider-content--13947";
export var title = "Spotlight-module--title--e5aca";
export var wrapper = "Spotlight-module--wrapper--a84e8";
export var wrapperBg = "Spotlight-module--wrapper-bg--9d550";