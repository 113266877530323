import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import { extractGatsbyImageDataRelativePath } from '@utils/imageFile'

import * as cx from './CompareWebsites.module.scss'

const AdsCompareWebsites = ({
  title = 'Compare Websites',
  description = 'Find out which website suits you best.',
  label = 'Find Your Match',
  link = '/',
  background = 'https://resources.agentimage.com/redesign/local/ads/compare-bg.jpg',
  image = 'https://resources.agentimage.com/redesign/local/ads/compare-devices-v4.png',
  compare_table = 'https://resources.agentimage.com/redesign/local/ads/compare-table.png',
}) => {
  return (
    <section className={cx.wrapper}>
      <div
        className={cx.wrapperBg}
        style={{
          backgroundImage: `url(${background})`,
        }}
      >
        <canvas
          width={832}
          height={995}
          style={{
            backgroundImage: `url(${image})`,
          }}
        ></canvas>
      </div>

      <div className={cx.content}>
        <h2 className="heading-2">{title}</h2>
        <p className="subtitle-5">{description}</p>
        <Link to={link} className="primary-button large" aria-label={label}>
          {label}
        </Link>
      </div>

      <img
        src={compare_table}
        alt="Compare Websites"
        className={cx.img}
        width={593}
        height={260}
      />
    </section>
  )
}

AdsCompareWebsites.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string,
  link: PropTypes.string,
  background: PropTypes.string,
  image: PropTypes.string,
  compare_table: PropTypes.string,
}

export default AdsCompareWebsites
