import React from 'react'
import PropTypes from 'prop-types'

import DeviceMobile from '../Mobile'
import DeviceTablet from '../Tablet'
import * as cx from './BespokeDesignDevice.module.scss'

const BespokeDesignDevice = ({
  wrapperClass = '',
  mobileSrc,
  tabletSrc,
  tabletAltSrc,
}) => {
  return (
    <div className={`${cx.wrapper} ${wrapperClass}`}>
      <canvas width={766} height={445} className={cx.placeholder}></canvas>
      <DeviceMobile imageSrc={mobileSrc} className={`${cx.mobile} mobile`} />
      <DeviceTablet
        imageSrc={tabletSrc}
        className={`${cx.tablet} ipad-landscape`}
      />
      <DeviceTablet
        imageSrc={tabletAltSrc}
        className={`${cx.tabletAlt} ipad-alt-landscape`}
      />
    </div>
  )
}

BespokeDesignDevice.propTypes = {
  wrapperClass: PropTypes.string,
  mobileSrc: PropTypes.string.isRequired,
  tabletSrc: PropTypes.string.isRequired,
  tabletAltSrc: PropTypes.string.isRequired,
}

export default BespokeDesignDevice
