import React from 'react'
import PropTypes from 'prop-types'

import BespokeDesignDevice from '@components/global/devices/BespokeDesignDevice'
import ImageBgOptimize from '/content/assets/real-estate-websites/imagine-studio/optimized-bg.jpg'
import * as cx from './QualityFeatures.module.scss'

const RewImagineStudioQualityFeatures = ({
  optimized_title = 'Optimized for all screen sizes',
  optimized_description = 'Imagine Studio websites are optimized for easy viewing on different screen sizes. Make a stunning first impression with custom designed template that delivers on quality and style.',
  optimized_background,
  optimize_image,
  custom_title = 'Custom Designed Pages for You',
  custom_description = 'We have the best product suited for your needs - no matter what your expertise.',
  custom_background,
  custom_image,
  locale_title = 'Bespoke Design For Your Locale',
  locale_description = 'Imagine Studio websites are specifically built for you and your team’s needs.',
  locale_background,
  locale_tablet_alt,
  locale_tablet,
  locale_mobile,
}) => {
  return (
    <section>
      <div className={cx.row}>
        {/* Optimized for all screen sizes */}
        <div
          className={cx.optimized}
          style={{ backgroundImage: `url(${optimized_background})` }}
        >
          <div className={cx.heading}>
            <h2 className="heading-4">{optimized_title}</h2>
            <p className="subtitle-5">{optimized_description}</p>
          </div>

          <div className={cx.feature}>
            <img
              src={optimize_image.url}
              alt={optimized_title}
              width={720}
              height={448}
            />
          </div>
        </div>

        {/* {Custom Designed Pages for You} */}
        <div
          className={cx.custom}
          style={{ backgroundImage: `url(${custom_background})` }}
        >
          <div className={cx.heading}>
            <h2 className="heading-4">{custom_title}</h2>
            <p className="subtitle-5">{custom_description}</p>
          </div>

          <div className={cx.feature}>
            <img
              src={custom_image.url}
              alt={custom_title}
              width={720}
              height={448}
            />
          </div>
        </div>
      </div>
      <div
        className={`${cx.row} ${cx.locale}`}
        style={{ backgroundImage: `url(${locale_background})` }}
      >
        {/* {Bespoke Design For Your Locale} */}
        <div className={cx._container}>
          <div className={cx.heading}>
            <h2 className="heading-4">{locale_title}</h2>
            <p className="subtitle-5">{locale_description}</p>
          </div>
          <div className={cx.device}>
            <BespokeDesignDevice
              mobileSrc={locale_tablet_alt.url}
              tabletSrc={locale_tablet.url}
              tabletAltSrc={locale_mobile.url}
            />
          </div>
        </div>
        <canvas
          className={cx.accent}
          style={{ backgroundImage: `url(${ImageBgOptimize})` }}
          width={436}
          height={382}
        />
      </div>
    </section>
  )
}

RewImagineStudioQualityFeatures.propTypes = {
  optimized_title: PropTypes.string,
  optimized_description: PropTypes.string,
  optimized_background: PropTypes.any,
  optimize_image: PropTypes.any,
  custom_title: PropTypes.string,
  custom_description: PropTypes.string,
  custom_background: PropTypes.any,
  custom_image: PropTypes.any,
  locale_title: PropTypes.string,
  locale_description: PropTypes.string,
  locale_background: PropTypes.any,
  locale_tablet_alt: PropTypes.any,
  locale_tablet: PropTypes.any,
  locale_mobile: PropTypes.any,
}

export default RewImagineStudioQualityFeatures
