import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import useWindowPosition from '@utils/useWindowPosition'
import clsx from 'clsx'
import { modifyImageKeys } from '@utils/modifyImageKeys'

// Component
import Seo from '@components/seo/Index'
import LayoutInnerPage from '@src/layouts/InnerPage'
import Gallery from '@components/pages/real-estate-websites/Gallery'
import ISBanner from '@components/global/banner/ImagingStudioBanner'
import RewImagineStudioSpotlight from '@components/pages/real-estate-websites/imaginestudio/Spotlight'
import RewImagineStudioDivider from '@components/pages/real-estate-websites/imaginestudio/Divider'
import RewImagineStudioReviews from '@components/pages/real-estate-websites/imaginestudio/Reviews'
import RewImagineStudioQualityFeatures from '@components/pages/real-estate-websites/imaginestudio/QualityFeatures'
import WebsiteFeaturesAlt from '@components/pages/real-estate-websites/WebsiteFeaturesAlt'
import Ads10BestWebsites from '@components/global/ads/10BestWebsites'
import AdsCompareWebsites from '@components/global/ads/CompareWebsites'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

// CSS
import * as cx from './ImagineStudio.module.scss'

// Image Accent
import ImageGalleryBackground from '/content/assets/real-estate-websites/imagine-studio/background.jpg'

// Icons List
const icons = ['doc-detail', 'pencil']

const ProductGalleryImagineStudio = ({
  data: { page },
  pageContext: { seo, uri },
}) => {
  let posts = page?.productAixInputs?.gallery?.items.map((item, index) => {
    return {
      name: item?.name,
      domain: item?.url,
      mobile: `${item?.mobile?.url}`,
      tablet: `${item?.tablet?.url}`,
      accent: true,
    }
  })
  let luxuryPosts = page?.productISInputs?.luxury_gallery?.items.map(
    (item, index) => {
      return {
        name: item.client_name,
        domain: item.client_url,
        mobile: `${item?.mobile?.url}`,
        tablet: `${item?.tablet?.url}`,
        accent: true,
        accentBottom: true,
      }
    }
  )
  let reviewItems = page?.productISInputs?.reviews?.items.map((item, index) => {
    let video = item.video_link != '' ? item.video_link : null
    let template = item.mobile.url != null ? 'tablet-mobile' : 'tablet'
    let mobile = item.mobile.url != null ? item.mobile.url : null
    return {
      name: item.client_name,
      domain: item.client_domain,
      video: video,
      testimonial: item.testimonials,
      background: {
        src: item.background.url,
        width: item.background.width,
        height: item.background.height,
      },
      logo: {
        src: item.logo.url,
        width: item.logo.width,
        height: item.logo.height,
      },
      logoLarge: {
        src: item.logo.url,
        width: item.logo.width,
        height: item.logo.height,
      },
      agent: {
        src: item.client_image.url,
        width: item.client_image.width,
        height: item.client_image.height,
      },
      template: template,
      tablet: item.tablet.url,
      mobile: mobile,
    }
  })
  let c = 0
  let highlightsItems = page?.productFeatures?.highlights.map((item, index) => {
    let icon = icons[c]
    c++
    return {
      icon: icon,
      content: item?.content,
    }
  })
  return (
    <LayoutInnerPage hasWhiteTransparentNav>
      <Seo title={page.title} seo={seo} uri={uri} />
      <ISBanner
        breadcrumbs={page?.seo?.breadcrumbs}
        title={page?.productISInputs?.banner?.title}
        description={page?.productISInputs?.banner?.description}
        button_text={page?.productISInputs?.banner?.button_label}
        button_link={page?.productISInputs?.banner?.button_link}
        devices={{
          mobile: `${page?.productISInputs?.banner?.mobile?.url}`,
          tablet: `${page?.productISInputs?.banner?.tablet?.url}`,
          laptop: `${page?.productISInputs?.banner?.laptop?.url}`,
        }}
        background={page?.productISInputs?.banner?.background?.url}
      />
      <Gallery
        wrapperClass={cx.gallery}
        wrapperBg={ImageGalleryBackground}
        title={page?.productAixInputs?.gallery?.title}
        description={page?.productAixInputs?.gallery?.description}
        posts={posts}
        postsPerPage={8}
        style="alt"
      />
      <RewImagineStudioSpotlight
        title={page?.productISInputs?.spotlight?.title}
        background={page?.productISInputs?.spotlight?.background?.url}
        items={page?.productISInputs?.spotlight?.items}
      />
      <RewImagineStudioDivider
        title={page?.productSemiCustom?.separator?.title}
        description={page?.productSemiCustom?.separator?.description}
      />
      <Gallery
        title="Imagine Studio"
        showTitle={false}
        posts={luxuryPosts}
        postsPerPage={4}
        loadMoreButtonTextDesktop="View more Imagine Studio websites"
        loadMoreButtonTextMobile="More Imagine Studio websites"
        style="alt"
      />
      <RewImagineStudioReviews
        title={page?.productISInputs?.reviews?.title}
        description={page?.productISInputs?.reviews?.description}
        reviewItems={reviewItems}
      />
      <RewImagineStudioQualityFeatures
        optimized_title={page?.productISInputs?.custom_features?.optimize_title}
        optimized_description={
          page?.productISInputs?.custom_features?.optimize_description
        }
        optimized_background={
          page?.productISInputs?.custom_features?.optimize_background?.url
        }
        optimize_image={page?.productISInputs?.custom_features?.optimize_image}
        custom_title={page?.productISInputs?.custom_features?.custom_title}
        custom_description={
          page?.productISInputs?.custom_features?.custom_description
        }
        custom_background={
          page?.productISInputs?.custom_features?.custom_background?.url
        }
        custom_image={page?.productISInputs?.custom_features?.custom_image}
        locale_title={page?.productISInputs?.custom_features?.locale_title}
        locale_description={
          page?.productISInputs?.custom_features?.locale_description
        }
        locale_background={
          page?.productISInputs?.custom_features?.locale_background?.url
        }
        locale_tablet_alt={
          page?.productISInputs?.custom_features?.locale_tablet_alt
        }
        locale_tablet={page?.productISInputs?.custom_features?.locale_tablet}
        locale_mobile={page?.productISInputs?.custom_features?.locale_mobile}
      />
      <WebsiteFeaturesAlt
        wrapperClassName={cx.websiteFeatures}
        bannerBg={page?.productFeatures?.background?.url}
        isBannerBgFull={true}
        title={page.title}
        bannerLaptop={page?.productFeatures?.laptop?.url}
        bannerMobile={page?.productFeatures?.mobile?.url}
        bestForItems={page?.productFeatures?.bestFor}
        highlightsItems={highlightsItems}
        featuresItems={page?.productFeatures?.more}
      />
      <div className={`ads ${cx.ads}`}>
        <div className="container">
          <Ads10BestWebsites
            background={page?.productISInputs?.ads?.ads_10best_background?.url}
            name={page.title}
            description={page?.productISInputs?.ads?.ads_10best_description}
            image={page?.productISInputs?.ads?.ads_10best_image?.url}
            imageWidth={587}
            imageHeight={341}
            label={page?.productISInputs?.ads?.ads_10best_label}
            link={page?.productISInputs?.ads?.ads_10best_url}
            invert={false}
          />
          <AdsCompareWebsites
            title={page?.productISInputs?.ads?.ads_compare_title}
            description={page?.productISInputs?.ads?.ads_compare_description}
            label={page?.productISInputs?.ads?.ads_compare_label}
            link={page?.productISInputs?.ads?.ads_compare_url}
            background={page?.productISInputs?.ads?.ads_compare_background?.url}
            image={page?.productISInputs?.ads?.ads_compare_image?.url}
            compare_table={
              page?.productISInputs?.ads?.ads_compare_table_image?.url
            }
          />
        </div>
      </div>
      <LeadContactForm
        version="LCF02"
        modelOrBackground="Jada"
        title="Free Consultation"
        subtitle="Get In Touch With Us For A"
        submitLabel="I want a stunning website!"
        leadName="Lead Contact Form"
        layout="default"
        formLocation="footer"
      />
    </LayoutInnerPage>
  )
}

export default ProductGalleryImagineStudio

export const pageQuery = graphql`
  query ProductGalleryImagineStudioPageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      seo {
        breadcrumbs {
          text
          url
        }
      }

      productISInputs {
        ads {
          ads_10best_title
          ads_10best_subtitle
          ads_10best_description
          ads_10best_label
          ads_10best_url
          ads_10best_background {
            url
            width
            height
            sizes
            srcset
            alt
            modified
          }
          ads_10best_image {
            url
            width
            height
            sizes
            srcset
            alt
            modified
          }
          ads_compare_title
          ads_compare_description
          ads_compare_label
          ads_compare_url
          ads_compare_background {
            url
            width
            height
            sizes
            srcset
            alt
            modified
          }
          ads_compare_image {
            url
            width
            height
            sizes
            srcset
            alt
            modified
          }
          ads_compare_table_image {
            url
            width
            height
            sizes
            srcset
            alt
          }
        }
        banner {
          title
          description
          button_label
          button_link
          background {
            url
            width
            height
            sizes
            srcset
            alt
            modified
          }
          laptop {
            url
            width
            height
            sizes
            srcset
            alt
            modified
          }
          mobile {
            url
            width
            height
            sizes
            srcset
            alt
            modified
          }
          tablet {
            url
            width
            height
            sizes
            srcset
            alt
            modified
          }
        }
        spotlight {
          title
          background {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          items {
            title
            description
            image {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
            logo {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
        }
        reviews {
          title
          description
          button_label
          button_url
          items {
            client_name
            client_domain
            video_link
            testimonials
            background {
              url
              width
              height
              sizes
              srcset
              alt
              modified
            }
            client_image {
              url
              width
              height
              sizes
              srcset
              alt
              modified
            }
            logo {
              url
              width
              height
              sizes
              srcset
              alt
              modified
            }
            tablet {
              url
              width
              height
              sizes
              srcset
              alt
              modified
            }
            mobile {
              url
              width
              height
              sizes
              srcset
              alt
              modified
            }
          }
        }
        custom_features {
          optimize_title
          optimize_description
          optimize_background {
            url
            width
            height
            sizes
            srcset
            alt
            modified
          }
          optimize_image {
            url
            width
            height
            sizes
            srcset
            alt
            modified
          }
          custom_title
          custom_description
          custom_background {
            url
            width
            height
            sizes
            srcset
            alt
            modified
          }
          custom_image {
            url
            width
            height
            sizes
            srcset
            alt
            modified
          }
          locale_title
          locale_description
          locale_background {
            url
            width
            height
            sizes
            srcset
            alt
            modified
          }
          locale_tablet_alt {
            url
            width
            height
            sizes
            srcset
            alt
            modified
          }
          locale_tablet {
            url
            width
            height
            sizes
            srcset
            alt
            modified
          }
          locale_mobile {
            url
            width
            height
            sizes
            srcset
            alt
            modified
          }
        }
        luxury_gallery {
          title
          description
          items {
            client_name
            client_url
            display
            tablet {
              url
              width
              height
              sizes
              srcset
              alt
              modified
            }
            mobile {
              url
              width
              height
              sizes
              srcset
              alt
              modified
            }
          }
        }
      }
      productAixInputs {
        gallery {
          title
          description
          items {
            name
            url
            tablet {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
            mobile {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
        }
      }
      productSemiCustom {
        separator {
          title
          description
        }
      }
      productFeatures {
        background {
          url
          srcset
          sizes
          width
          height
          alt
          modified
        }
        accent {
          url
          srcset
          sizes
          width
          height
          alt
          modified
        }
        laptop {
          url
          srcset
          sizes
          width
          height
          alt
          modified
        }
        mobile {
          url
          srcset
          sizes
          width
          height
          alt
          modified
        }
        bestFor {
          content
          check
        }
        highlights {
          content
          check
        }
        more {
          content
          check
        }
      }
      leadContactForm {
        title
        subtitle
        version
        submit
        model
        background {
          url
          srcset
          sizes
          width
          height
          alt
          modified
        }
      }
    }
  }
`
