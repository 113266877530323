import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import parse from 'html-react-parser'

import ProductGalleryPhoneTablet from '@components/global/devices/ProductGalleryPhoneTablet'
import PhoneLaptopV1 from '@components/global/devices/PhoneLaptopV1'
import ToggleAccordion from '@components/global/accordions/ToggleAccordion'
import CheckItems from '@components/global/CheckItems'
import * as cx from './WebsiteFeaturesAlt.module.scss'

const WebsiteFeaturesAlt = ({
  wrapperClassName = '',
  wrapperBg,
  bannerBg,
  isBannerBgFull = false,
  title = 'Agent Image X',
  bannerLaptop,
  bannerMobile,
  bestForItems,
  highlightsItems,
  featuresItems,
}) => {
  return (
    <section
      className={`${cx.wrapper} ${
        wrapperBg ? cx.wrapperBg : ''
      } ${wrapperClassName}`}
      style={{ backgroundImage: wrapperBg ? `url(${wrapperBg})` : null }}
    >
      <div id="website-features" className={cx.scrollId}></div>
      <div
        className={`${cx.bannerBg} ${
          isBannerBgFull ? cx.full : ''
        } website-features-banner-bg`}
        style={{ backgroundImage: `url(${bannerBg})` }}
      ></div>

      <div
        className={`container-stretch ${cx.banner} ${
          isBannerBgFull ? cx.full : ''
        }`}
      >
        <h2>
          <span className={`subtitle-1 ${cx.headingTitle}`}>
            {parse(title)}
          </span>
          <span className="heading-2">Website Features</span>
        </h2>

        <div className="row website-features-alt-row">
          <div className={`col website-features-alt-devices ${cx.devices}`}>
            {isBannerBgFull && (
              <ProductGalleryPhoneTablet
                wrapperClass={cx.deviceAlt}
                mobileSrc={bannerMobile}
                tabletSrc={bannerLaptop}
              />
            )}
            {!isBannerBgFull && (
              <PhoneLaptopV1
                wrapperClass={cx.device}
                mobileSrc={bannerMobile}
                laptopSrc={bannerLaptop}
              />
            )}
          </div>
          <div
            className={`col website-features-alt-highlights ${cx.highlights}`}
          >
            <div className="subtitle-1">Best For</div>
            <CheckItems
              parentClass={cx.bestFor}
              items={bestForItems}
              itemClass="default-body large"
            />
            <div className={cx.spacer}></div>
            <div className="subtitle-1">Highlights</div>
            <CheckItems
              items={highlightsItems}
              itemClass="default-body large"
            />
          </div>
        </div>
      </div>

      <div className={`container-stretch ${cx.content}`}>
        <div className="subtitle-1">More Features</div>
        <div className="row">
          <div className={`col ${cx.features}`}>
            <CheckItems items={featuresItems} itemClass="default-body large" />
          </div>
        </div>
      </div>

      <ToggleAccordion
        title="More Features"
        btnClass="subtitle-1"
        parentClass={cx.accordion}
      >
        <CheckItems items={featuresItems} itemClass="default-body large" />
      </ToggleAccordion>

      <div className={`${cx.link} website-features-alt-link`}>
        <Link to="/" className="primary-button large" aria-label={title}>
          Get Started With {title}
        </Link>
      </div>
    </section>
  )
}

WebsiteFeaturesAlt.propTypes = {
  wrapperClassName: PropTypes.string,
  wrapperBg: PropTypes.string,
  bannerBg: PropTypes.string,
  isBannerBgFull: PropTypes.bool,
  title: PropTypes.string,
  bannerLaptop: PropTypes.string,
  bannerMobile: PropTypes.string,
  bestForItems: PropTypes.array,
  highlightsItems: PropTypes.array,
  featuresItems: PropTypes.array,
}

export default WebsiteFeaturesAlt
