import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { wpautop } from '@utils/wpautop'
import parse from 'html-react-parser'

import ProductGalleryPhoneTablet from '@components/global/devices/ProductGalleryPhoneTablet'
import ProductGalleryPhoneTabletAlt from '@components/global/devices/ProductGalleryPhoneTabletAlt'
import SvgArrowAlt from '@svg/arrowAlt.inline'
import * as cx from './Gallery.module.scss'

const Gallery = ({
  wrapperClass = '',
  wrapperBg = '',
  showTitle = true,
  title = 'Professional Website Themes',
  description = 'These luxury themes are a stunning combination of contemporary design and upscale styling.',
  posts,
  postsPerPage = 4,
  buttonText = 'Get Pricing & Features',
  loadMoreButtonTextDesktop = 'View more websites',
  loadMoreButtonTextMobile = 'More websites',
  style = 'default',
}) => {
  const [numberItemsPagination, setNumberItemsPagination] = useState(
    postsPerPage
  )

  const loadMoreItems = (arrayLength) => {
    const numberItems =
      numberItemsPagination + postsPerPage < arrayLength
        ? numberItemsPagination + postsPerPage
        : arrayLength
    setNumberItemsPagination(numberItems)
  }

  return (
    <section
      className={`${cx.wrapper} ${wrapperClass}`}
      style={{
        backgroundImage: wrapperBg !== '' ? `url(${wrapperBg})` : false,
      }}
    >
      <div className={`${cx.heading} gallery-title`}>
        {showTitle && title !== 'Imagine Studio' && (
          <>
            <h2 className="heading-3">{parse(title)}</h2>
            {parse(wpautop(description, 'subtitle-5'))}
          </>
        )}
        {showTitle && title === 'Imagine Studio' && (
          <>
            <h2 className={cx.exclusiveHeading}>
              <span className="subtitle-7">Exclusive</span>
              <span className="heading-7">Website Design</span>
            </h2>

            <div className={cx.exclusiveSeparator}></div>

            {parse(wpautop(description, 'default-body large'))}
          </>
        )}
      </div>

      <div className={`${cx.items} gallery-items`}>
        {posts.slice(0, numberItemsPagination).map((post, index) => {
          return (
            <div
              key={index}
              className={`${cx.item} gallery-item ${
                post.accent !== undefined ? cx.hasAccent : ''
              }`}
            >
              {post.accent !== undefined && (
                <canvas
                  className={`${cx.itemAccent} ${
                    post.accentBottom !== undefined ? cx.accentBottom : ''
                  }`}
                  width={188}
                  height={188}
                />
              )}
              {style === 'default' && (
                <ProductGalleryPhoneTablet
                  mobileSrc={post.mobile}
                  tabletSrc={post.tablet}
                />
              )}
              {style === 'alt' && (
                <ProductGalleryPhoneTabletAlt
                  wrapperClass={cx.devicesAlt}
                  mobileSrc={post.mobile}
                  laptopSrc={post.tablet}
                />
              )}
              <div
                className={`${cx.itemDetails} ${
                  style === 'alt' ? cx.itemCol : ''
                }`}
              >
                <div className={cx.itemName}>
                  <h3 className="heading-6">{post.name}</h3>
                </div>
                <Link
                  to="/"
                  className="primary-button large"
                  aria-label={buttonText}
                >
                  {buttonText} <SvgArrowAlt />
                </Link>
              </div>
            </div>
          )
        })}
      </div>

      {posts.length > numberItemsPagination && (
        <div className={cx.loadMore}>
          <button
            aria-label="load more"
            className="primary-button large alt svg-rotate"
            onClick={() => loadMoreItems(posts.length)}
          >
            <span className={cx.desktop}>{loadMoreButtonTextDesktop}</span>
            <span className={cx.mobile}>{loadMoreButtonTextMobile}</span>
            <SvgArrowAlt />
          </button>
        </div>
      )}
    </section>
  )
}

Gallery.propTypes = {
  wrapperClass: PropTypes.string,
  wrapperBg: PropTypes.string,
  showTitle: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  posts: PropTypes.array.isRequired,
  postsPerPage: PropTypes.number,
  buttonText: PropTypes.string,
  loadMoreButtonTextDesktop: PropTypes.string,
  loadMoreButtonTextMobile: PropTypes.string,
  style: PropTypes.oneOf(['default', 'alt']),
}

export default Gallery
