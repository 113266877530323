// extracted by mini-css-extract-plugin
export var accentBottom = "Gallery-module--accent-bottom--9fbd9";
export var desktop = "Gallery-module--desktop--069a3";
export var devicesAlt = "Gallery-module--devices-alt--6c223";
export var exclusiveHeading = "Gallery-module--exclusive-heading--1e69b";
export var exclusiveSeparator = "Gallery-module--exclusive-separator--c2ccc";
export var hasAccent = "Gallery-module--has-accent--cc3b1";
export var heading = "Gallery-module--heading--e5e8b";
export var item = "Gallery-module--item--201d8";
export var itemAccent = "Gallery-module--item-accent--242ef";
export var itemCol = "Gallery-module--item-col--722e2";
export var itemDetails = "Gallery-module--item-details--12263";
export var itemName = "Gallery-module--item-name--70267";
export var items = "Gallery-module--items--0703c";
export var loadMore = "Gallery-module--load-more--830bd";
export var mobile = "Gallery-module--mobile--bab6d";
export var wrapper = "Gallery-module--wrapper--5c574";