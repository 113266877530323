import React from 'react'
import PropTypes from 'prop-types'

import DeviceMobile from '../Mobile'
import DeviceLaptop from '../Laptop'
import * as cx from './PhoneLaptopV1.module.scss'

const PhoneLaptopV1 = ({
  wrapperClass = '',
  mobileSrc = '',
  laptopSrc = '',
}) => {
  return (
    <div className={`${cx.wrapper} ${wrapperClass}`}>
      <canvas width={900} height={546} className={cx.placeholder}></canvas>
      <DeviceMobile imageSrc={mobileSrc} className={`${cx.mobile} mobile`} />
      <DeviceLaptop imageSrc={laptopSrc} className={`${cx.laptop} laptop`} />
    </div>
  )
}

PhoneLaptopV1.propTypes = {
  wrapperClass: PropTypes.string,
  mobileSrc: PropTypes.string,
  laptopSrc: PropTypes.string,
}

export default PhoneLaptopV1
