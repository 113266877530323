// extracted by mini-css-extract-plugin
export var accordion = "WebsiteFeaturesAlt-module--accordion--f1a34";
export var banner = "WebsiteFeaturesAlt-module--banner--92cc0";
export var bannerBg = "WebsiteFeaturesAlt-module--banner-bg--45e22";
export var bestFor = "WebsiteFeaturesAlt-module--best-for--a3a1a";
export var content = "WebsiteFeaturesAlt-module--content--ecce7";
export var device = "WebsiteFeaturesAlt-module--device--2c331";
export var deviceAlt = "WebsiteFeaturesAlt-module--device-alt--c4717";
export var devices = "WebsiteFeaturesAlt-module--devices--6fbc0";
export var features = "WebsiteFeaturesAlt-module--features--1e5cb";
export var full = "WebsiteFeaturesAlt-module--full--8cf02";
export var headingTitle = "WebsiteFeaturesAlt-module--heading-title--a7756";
export var highlights = "WebsiteFeaturesAlt-module--highlights--bf1eb";
export var link = "WebsiteFeaturesAlt-module--link--542d8";
export var scrollId = "WebsiteFeaturesAlt-module--scroll-id--ec5fe";
export var spacer = "WebsiteFeaturesAlt-module--spacer--e26ce";
export var wrapper = "WebsiteFeaturesAlt-module--wrapper--00958";
export var wrapperBg = "WebsiteFeaturesAlt-module--wrapper-bg--4c93d";