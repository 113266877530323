import React from 'react'
import PropTypes from 'prop-types'

import DeviceMobile from '../Mobile'
import DeviceTabletAlt from '../TabletAlt'
import DeviceLaptopAlt from '../LaptopAlt'
import * as cx from './ImagineStudioDeviceBanner.module.scss'

const ImagineStudioDeviceBanner = ({
  wrapperClass = '',
  mobileSrc,
  tabletSrc,
  laptopSrc,
}) => {
  return (
    <div className={`${cx.wrapper} ${wrapperClass}`}>
      <canvas width={915} height={317} className={cx.placeholder}></canvas>
      <DeviceMobile className={`${cx.mobile} mobile`} imageSrc={mobileSrc} />
      <DeviceTabletAlt className={`${cx.tablet} tablet`} imageSrc={tabletSrc} />
      <DeviceLaptopAlt className={`${cx.laptop} laptop`} imageSrc={laptopSrc} />
    </div>
  )
}

ImagineStudioDeviceBanner.propTypes = {
  wrapperClass: PropTypes.string,
  mobileSrc: PropTypes.string,
  tabletSrc: PropTypes.string,
  laptopSrc: PropTypes.string,
}

export default ImagineStudioDeviceBanner
