import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import clsx from 'clsx'

import * as cx from './10BestWebsites.module.scss'

const Ads10BestWebsites = ({
  background,
  wrapperClassName = '',
  name,
  description = 'See our ' + name + ' themes in action.',
  descriptionClassName = '',
  image,
  imageWidth,
  imageHeight,
  label = 'Learn More',
  link = '/',
  invert = false,
}) => {
  return (
    <section
      className={clsx(
        wrapperClassName,
        cx.wrapper,
        invert && cx.invert,
        'ads-best-websites'
      )}
    >
      <div
        className={clsx(cx.wrapperBg, 'ads-best-websites-bg')}
        style={{
          backgroundImage:
            background !== undefined ? `url(${background})` : null,
        }}
      ></div>
      <div className={clsx(cx.content, 'ads-best-websites-content')}>
        <div className="subtitle-1">10 Best</div>
        <h2 className="heading-2">
          {name} <br />
          Websites
        </h2>
        <div className={clsx(descriptionClassName, 'subtitle-5')}>
          {description}
        </div>
        <Link to={link} className="primary-button large" aria-label={label}>
          {label}
        </Link>
      </div>
      <img
        src={image}
        alt={name}
        className={clsx(cx.img, 'ads-best-websites-img')}
        width={imageWidth}
        height={imageHeight}
      />
    </section>
  )
}

Ads10BestWebsites.propTypes = {
  background: PropTypes.string,
  wrapperClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  descriptionClassName: PropTypes.string,
  image: PropTypes.string.isRequired,
  imageWidth: PropTypes.number.isRequired,
  imageHeight: PropTypes.number.isRequired,
  link: PropTypes.string,
  label: PropTypes.string,
  invert: PropTypes.bool,
}

export default Ads10BestWebsites
