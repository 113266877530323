import React from 'react'
import PropTypes from 'prop-types'
import { getImage } from 'gatsby-plugin-image'
import { modifyImageKeys } from '@utils/modifyImageKeys'

import ElementImage from '@components/global/element/Image'
import ElementImageFile from '@components/global/element/ImageFile'
import * as cx from './Laptop.module.scss'
import clsx from 'clsx'

const DeviceLaptop = ({
  imageSrc,
  imagePlaceholder = 'https://resources.agentimage.com/redesign/local/laptop-placeholder.png',
  className = '',
  imageClassName,
  imageFile,
  imageFileAlt = '',
  splideLazyload = false,
  lazy = true,
  placeholderWidth = 900,
  placeholderHeight = 546,
  width = 900,
  height = 546,
  imageStyle,
}) => {
  let image = null

  if (imageFile !== null && imageFile !== undefined) {
    if (imageFile.modified) {
      image = modifyImageKeys(imageFile)
    } else {
      image = getImage(imageFile)
    }
  }

  const customImagePlaceholder =
    imagePlaceholder !==
    '/static/laptop-placeholder-f20a17390fec88118b7587fc387a58bf.png'

  return (
    <div className={`${cx.wrapper} ${className}`}>
      <canvas
        width={placeholderWidth}
        height={placeholderHeight}
        style={{ backgroundImage: `url(${imagePlaceholder})` }}
      ></canvas>
      {image === null && (
        <canvas
          width={width}
          height={height}
          style={{ backgroundImage: `url(${imageSrc})`, ...imageStyle }}
          className={clsx([cx.canvas], imageClassName, {
            [cx.custom]: customImagePlaceholder,
          })}
        ></canvas>
      )}
      {image !== null && splideLazyload && (
        <ElementImage
          src={image?.images.fallback.src}
          width={width}
          height={height}
          alt={imageFileAlt}
          srcSet={image?.images.fallback.srcSet}
          sizes={image?.images.fallback.sizes}
          splideLazyload={splideLazyload}
          className={clsx([cx.image], imageClassName)}
        />
      )}
      {image !== null && !splideLazyload && (
        <ElementImageFile
          src={image}
          width={width}
          height={height}
          alt={imageFileAlt}
          className={clsx([cx.image], imageClassName)}
          lazy={lazy}
        />
      )}
    </div>
  )
}

DeviceLaptop.propTypes = {
  imageSrc: PropTypes.string,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  imageStyle: PropTypes.object,
  imagePlaceholder: PropTypes.string,
  imageFile: PropTypes.object,
  imageFileAlt: PropTypes.string,
  splideLazyload: PropTypes.bool,
  lazy: PropTypes.bool,
  placeholderWidth: PropTypes.number,
  placeholderHeight: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default DeviceLaptop
